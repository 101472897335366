<template>
  <div>
      <v-card>
          <v-container grid-list-md fluid class="padContentLess">

              <v-layout row wrap style="height:100%">
                  <v-flex v-bind:class="[showDataOnSide ? 'md6' : 'md12']" style="max-height: 850px; overflow: auto;">
                      <v-form @submit="editTransactionCheck($v.editTransaction, $v.transactionBalances)">
                          <v-app-bar dense absolute text class="fixedToolbar" style="z-index: 2;">
                              <v-toolbar-title v-bind:class="{'headline grey-text': !drawer, 'padLeft headline grey-text': drawer }">Edit Transaction</v-toolbar-title>
                              <v-spacer></v-spacer>
                              <v-btn icon v-if="editTransaction.currency !== '' && editTransaction.transactionType === 'expense'" v-on:click.prevent="showDataOnSide = !showDataOnSide">
                                  <v-badge color="orange"
                                           overlap
                                           :content="itemsBadge"
                                           :value="itemsBadge">
                                      <v-icon color="orange">far fa-list-alt</v-icon>
                                  </v-badge>
                              </v-btn>
                              <v-btn icon type="submit" v-on:click.prevent="editTransactionCheck($v.editTransaction, $v.transactionBalances)">
                                  <v-icon color="green">fas fa-check</v-icon>
                              </v-btn>
                              <v-btn icon @click.native="$router.push({ path: '/dashboard/transaction' })">
                                  <v-icon color="red">fas fa-times</v-icon>
                              </v-btn>
                          </v-app-bar>

                          <v-card-text>
                              <v-container grid-list-md>
                                  <v-layout wrap>
                                      <v-flex xs12 md6>
                                          <v-btn-toggle rounded mandatory v-model="editTransaction.transactionType" style="width: 100%; margin-top: 15px;">
                                              <v-btn text color="blue" value="income" style="width: 33.33%">
                                                  Income
                                              </v-btn>
                                              <v-btn text color="blue" value="expense" style="width: 33.33%">
                                                  Expense
                                              </v-btn>
                                              <v-btn text color="blue" value="transfer" style="width: 33.33%">
                                                  Transfer
                                              </v-btn>
                                          </v-btn-toggle>
                                      </v-flex>
                                      <v-flex xs12 md6>
                                          <v-autocomplete v-bind:items="currency"
                                                          label="Currency"
                                                          v-model="editTransaction.currency"
                                                          :error-messages="currencyErrors"
                                                          @input="$v.editTransaction.currency.$touch()"
                                                          @blur="$v.editTransaction.currency.$touch()"
                                                          item-text="currencyName"
                                                          item-value="currencyShort">
                                              <template slot="selection"
                                                        slot-scope="data">
                                                  <v-chip :input-value="data.selected"
                                                          class="chip--select-multi">
                                                      {{ data.item.currencyName }}
                                                  </v-chip>
                                              </template>
                                              <template slot="item"
                                                        slot-scope="data">

                                                  <template>
                                                      <v-list-item-content>
                                                          <v-list-item-title v-html="data.item.currencyName"></v-list-item-title>
                                                          <v-list-item-subtitle v-html="data.item.currencyShort"></v-list-item-subtitle>
                                                      </v-list-item-content>
                                                  </template>
                                              </template>
                                          </v-autocomplete>
                                      </v-flex>
                                      <v-flex xs12 v-if="convert">
                                          <v-expansion-panel popout>
                                              <v-expansion-panel-content>
                                                  <div slot="header"><b>Conversion Rates</b></div>
                                                  <v-card>
                                                      <v-card-text>
                                                          <v-layout wrap>

                                                              <v-list two-line>
                                                                  <template v-for="item in conversions">
                                                                      <v-divider></v-divider>
                                                                      <v-list-item :key="item.title">
                                                                          <v-list-item-content>
                                                                              <v-list-item-title v-html="item.convertFrom"></v-list-item-title>
                                                                              <-sub-title-subtitle v-html="item.convertTo"></-sub-title-subtitle>
                                                                          </v-list-item-content>
                                                                      </v-list-item>
                                                                  </template>
                                                              </v-list>

                                                          </v-layout>
                                                      </v-card-text>
                                                  </v-card>
                                              </v-expansion-panel-content>
                                          </v-expansion-panel>
                                      </v-flex>
                                      <v-flex xs12>
                                          <v-text-field v-model="editTransaction.transactionName"
                                                        label="Transaction Name"
                                                        :error-messages="transactionNameErrors"
                                                        @input="$v.editTransaction.transactionName.$touch()"
                                                        @blur="$v.editTransaction.transactionName.$touch()"></v-text-field>
                                      </v-flex>
                                      <v-flex xs12 v-if="editTransaction.transactionType !== 'transfer'">
                                          <v-autocomplete label="Account"
                                                          :items="account"
                                                          v-model="editTransaction.account"
                                                          item-text="accountName"
                                                          item-value="accountName"
                                                          chips
                                                          prepend-icon="fas fa-university"
                                                          hint="What Account does this belong to?"
                                                          persistent-hint
                                                          :change="adjustAccountToMatchBudget"
                                                          :error-messages="accountErrors"
                                                          @input="$v.editTransaction.account.$touch()"
                                                          @blur="$v.editTransaction.account.$touch()">
                                              <template slot="selection" slot-scope="data">
                                                  <v-chip @input="data.parent.selectItem(data.item)"
                                                          :input-value="data.selected"
                                                          class="chip--select-multi"
                                                          :key="JSON.stringify(data.item)">

                                                      {{ data.item.accountName }}
                                                  </v-chip>
                                              </template>
                                              <template slot="item" slot-scope="data">
                                                  <template v-if="typeof data.item !== 'object'">
                                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                                  </template>
                                                  <template v-else>
                                                      <v-list-item-content>
                                                          <v-list-item-title v-text="data.item.accountName"></v-list-item-title>
                                                          <v-list-item-subtitle v-text="asMoney(data.item.accountAmount)"></v-list-item-subtitle>
                                                      </v-list-item-content>
                                                  </template>
                                              </template>
                                          </v-autocomplete>
                                      </v-flex>
                                      <v-flex xs12 v-if="editTransaction.transactionType === 'expense'">
                                          <v-autocomplete label="Budget"
                                                          :items="budget"
                                                          v-model="editTransaction.budget"
                                                          chips
                                                          prepend-icon="fa-money-bill-alt"
                                                          hint="What Budget does this belong to?"
                                                          persistent-hint
                                                          :change="selectAndHideAccount"></v-autocomplete>
                                      </v-flex>
                                      <v-flex xs12 v-if="editTransaction.transactionType === 'transfer'">
                                          <v-autocomplete :items="transferAccount"
                                                          v-model="editTransaction.transferFrom"
                                                          label="Transfer From Account"
                                                          item-text="accountName"
                                                          item-value="accountName"
                                                          chips
                                                          prepend-icon="fa-balance-scale"
                                                          hint="Transfer from Account?"
                                                          persistent-hint
                                                          :error-messages="transferFromErrors"
                                                          @input="$v.editTransaction.transferFrom.$touch()"
                                                          @blur="$v.editTransaction.transferFrom.$touch()">
                                              <template slot="selection" slot-scope="data">
                                                  <v-chip @input="data.parent.selectItem(data.item)"
                                                          :input-value="data.selected"
                                                          class="chip--select-multi"
                                                          :key="JSON.stringify(data.item)">

                                                      {{ data.item.accountName }}
                                                  </v-chip>
                                              </template>
                                              <template slot="item" slot-scope="data">
                                                  <template v-if="typeof data.item !== 'object'">
                                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                                  </template>
                                                  <template v-else>

                                                      <v-list-item-content>
                                                          <v-list-item-title v-text="data.item.accountName"></v-list-item-title>

                                                      </v-list-item-content>
                                                  </template>
                                              </template>
                                          </v-autocomplete>
                                      </v-flex>
                                      <v-flex xs12 v-if="editTransaction.transactionType === 'expense'">
                                          <PlaceSold v-bind:sentPlaceSold="editTransaction.placeSold"
                                                     v-on:placeSold="editTransaction.placeSold = $event"></PlaceSold>
                                      </v-flex>
                                      <v-flex xs12 v-if="editTransaction.transactionType === 'expense'">
                                          <v-autocomplete :items="placeSoldLocations"
                                                          v-model="editTransaction.placeSoldLocation"
                                                          label="Place Sold Location"
                                                          prepend-icon="fas fa-map-marker-alt"
                                                          chips
                                                          item-text="locationName"
                                                          item-value="locationName">

                                              <template slot="selection"
                                                        slot-scope="data">
                                                  <v-chip :input-value="data.selected"
                                                          class="chip--select-multi">
                                                      {{ data.item.locationName }}
                                                  </v-chip>
                                              </template>
                                              <template slot="item"
                                                        slot-scope="data">

                                                  <template>
                                                      <v-list-item-content>
                                                          <v-list-item-title v-html="data.item.locationName"></v-list-item-title>
                                                          <v-list-item-subtitle v-html="data.item.locationAddress"></v-list-item-subtitle>
                                                      </v-list-item-content>
                                                  </template>
                                              </template>
                                          </v-autocomplete>
                                      </v-flex>
                                      <v-flex xs12 v-if="editTransaction.transactionType === 'transfer'">
                                          <v-autocomplete :items="transferAccount"
                                                          v-model="editTransaction.transferTo"
                                                          label="Transfer To Account"
                                                          item-text="accountName"
                                                          item-value="accountName"
                                                          chips
                                                          prepend-icon="fa-balance-scale"
                                                          hint="Transfer To Account?"
                                                          persistent-hint
                                                          :error-messages="transferToErrors"
                                                          @input="$v.editTransaction.transferTo.$touch()"
                                                          @blur="$v.editTransaction.transferTo.$touch()">
                                              <template slot="selection" slot-scope="data">
                                                  <v-chip @input="data.parent.selectItem(data.item)"
                                                          :input-value="data.selected"
                                                          class="chip--select-multi"
                                                          :key="JSON.stringify(data.item)">

                                                      {{ data.item.accountName }}
                                                  </v-chip>
                                              </template>
                                              <template slot="item" slot-scope="data">
                                                  <template v-if="typeof data.item !== 'object'">
                                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                                  </template>
                                                  <template v-else>

                                                      <v-list-item-content>
                                                          <v-list-item-title v-text="data.item.accountName"></v-list-item-title>
                                                      </v-list-item-content>
                                                  </template>
                                              </template>
                                          </v-autocomplete>
                                      </v-flex>
                                      <v-flex xs12>
                                          <Category v-bind:sentCategory="editTransaction.category" 
                                                    v-on:category="editTransaction.category = $event"></Category>
                                          <!--<v-autocomplete :items="category"
                                                          v-model="editTransaction.category"
                                                          label="Category"
                                                          chips
                                                          prepend-icon="fa-tags"
                                                          hint="What Category does this belong to?"
                                                          persistent-hint
                                                          :error-messages="categoryErrors"
                                                          @input="$v.editTransaction.category.$touch()"
                                                          @blur="$v.editTransaction.category.$touch()"></v-autocomplete>-->
                                      </v-flex>
                                      <v-flex xs12>
                                          <v-text-field label="Transaction Amount" prepend-icon="fa-money-bill-alt" type="number" min="1"
                                                        v-model="transactionBalances.transactionAmount"
                                                        :error-messages="transactionAmountErrors"
                                                        @input="$v.transactionBalances.transactionAmount.$touch()"
                                                        @blur="$v.transactionBalances.transactionAmount.$touch()"></v-text-field>

                                      </v-flex>
                                      <v-flex xs12>
                                          <v-select v-bind:items="paymentType"
                                                    v-model="transactionBalances.paymentType"
                                                    label="Payment Type"
                                                    chips
                                                    prepend-icon="fas fa-credit-card"
                                                    :error-messages="paymentTypeErrors"
                                                    @input="$v.transactionBalances.paymentType.$touch()"
                                                    @blur="$v.transactionBalances.paymentType.$touch()"></v-select>
                                      </v-flex>
                                      <v-flex xs12>
                                          <v-text-field label="Comment" prepend-icon="fa-comment" v-model="editTransaction.comment"></v-text-field>
                                      </v-flex>
                                      <!--<v-flex xs12>
        <v-switch v-model="editTransaction.isRecurring"
                  label="Recurring Transaction"></v-switch>
    </v-flex>
    <v-flex xs12 v-if="editTransaction.isRecurring">
        <v-layout wrap>
            <v-flex xs6>
                <v-text-field label="Repeat Every" type="number" min="1" max="60" v-model="editTransaction.repeat"></v-text-field>
            </v-flex>
            <v-flex xs6>
                <v-select v-model="edieditModelIncomeDatetTransaction.cycle"
                          :items="['Day','Week','Month','Year']"></v-select>
            </v-flex>
        </v-layout>
        <v-radio-group v-model="editTransaction.period" row>
            <v-radio label="Forever" value="Forever"></v-radio>
            <v-radio label="For Number of cycles" value="For"></v-radio>
        </v-radio-group>
        <v-text-field v-if="editTransaction.period === 'For'" label="Number of cycles" type="number" min="1" max="60" v-model="editTransaction.numberOfCycles"></v-text-field>

        <v-menu v-model="editModelGoesOff"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px">
            <template v-slot:activator="{ on }">
                <v-text-field v-model="editTransaction.goesOffFirst"
                              label="Start Date"
                              readonly
                              v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="editTransaction.goesOffFirst" @input="editModelGoesOff = false"></v-date-picker>
        </v-menu>

    </v-flex>-->
                                      <v-flex xs6 v-if="!editTransaction.isRecurring">
                                          <v-menu v-model="editModelTransactionDate"
                                                  :close-on-content-click="false"
                                                  :nudge-right="40"
                                                  transition="scale-transition"
                                                  offset-y
                                                  min-width="290px">
                                              <template v-slot:activator="{ on }">
                                                  <v-text-field v-model="transactionBalances.transactionDate"
                                                                label="Transaction Date"
                                                                readonly
                                                                v-on="on"></v-text-field>
                                              </template>
                                              <v-date-picker v-model="transactionBalances.transactionDate" @input="editModelTransactionDate = false"></v-date-picker>
                                          </v-menu>

                                      </v-flex>
                                      <v-flex xs6 v-if="!editTransaction.isRecurring">
                                          <v-menu ref="timemenu"
                                                  v-model="timemenuvisibilty"
                                                  :close-on-content-click="false"
                                                  :nudge-right="40"
                                                  :return-value.sync="transactionBalances.transactionTime"
                                                  transition="scale-transition"
                                                  offset-y
                                                  max-width="290px"
                                                  min-width="290px">
                                              <template v-slot:activator="{ on }">
                                                  <v-text-field v-model="transactionBalances.transactionTime"
                                                                label="Time"
                                                                prepend-icon="access_time"
                                                                readonly
                                                                v-on="on"></v-text-field>
                                              </template>
                                              <v-time-picker v-if="timemenuvisibilty"
                                                             v-model="transactionBalances.transactionTime"
                                                             full-width>
                                                  <v-spacer></v-spacer>
                                                  <v-btn text color="primary" @click="timemenuvisibilty = false">Cancel</v-btn>
                                                  <v-btn text color="primary" @click="$refs.timemenu.save(transactionBalances.transactionTime)">OK</v-btn>
                                              </v-time-picker>
                                          </v-menu>
                                      </v-flex>
                                  </v-layout>   
                              </v-container>
                          </v-card-text>
                      </v-form>
                  </v-flex>
                  <v-flex md6 v-if="showDataOnSide && $vuetify.breakpoint.mdAndUp" style="height:100%">
                      <Items v-bind:itype="'expense'" 
                             v-bind:itemIds="editTransaction.transactionBalances[0].transactionItems" 
                             v-bind:currency="editTransaction.currency" 
                             v-bind:placeSold="editTransaction.placeSold"
                             v-on:show="adjustShow($event)" 
                             v-on:ItemToAdd="addItemToExpenseTransaction($event)"
                             v-on:getPlaceSold="returnPlaceSold($event)"></Items>
                  </v-flex>
              </v-layout>
          </v-container>

        <v-dialog v-model="showDataOnSide" scrollable :fullscreen="$vuetify.breakpoint.smAndDown" v-if="$vuetify.breakpoint.smAndDown" hide-overlay transition="dialog-bottom-transition">
            <Items v-bind:itype="'expense'" 
                   v-bind:itemIds="editTransaction.transactionBalances[0].transactionItems" 
                   v-bind:currency="editTransaction.currency" 
                   v-bind:placeSold="editTransaction.placeSold"
                   v-on:show="adjustShow($event)" 
                   v-on:ItemToAdd="addItemToExpenseTransaction($event)"
                   v-on:getPlaceSold="returnPlaceSold($event)"></Items>
        </v-dialog>

                          

      </v-card>
    
  </div>
</template>


<script>
  import { validationMixin } from 'vuelidate'
  import { required, minValue } from 'vuelidate/lib/validators'

    import { globalFunctions, formatMoney } from '../../javascript/globalFunctions'
  import { transactionManager } from '../../javascript/transactionManager'
  import { dateManager } from '../../javascript/dateManager'

  export default {
    mixins: [validationMixin],
    components: {
        Items: () => import('../../components/transactionItems/transactionItems.vue'),
        Category: () => import('../../components/category/categorySelect.vue'),
        PlaceSold: () => import('../../components/placeSold/placeSoldSelect.vue')
    },
    props: ['drawer'],
    data() {
      return {
        showSnackbar: false,
        snackbarText: '',
        snackbarColor: '',
        itemsBadge: '',
        addItem: false,
        datemenu: false,
        timemenuvisibilty: false,
        editModelTransactionDate: false,
        editModelGoesOff: false,
        currency: [],
        account: [],
        budget: ["None"],
        transferAccount: [],
        selectedAccount: '',
        placeSold: [],
        placeSoldLocations: [],
        category: [],
        paymentType: ["Cash", "Credit Card", "Debit Card", "Bank Transfer", "Voucher"],
        editTransaction: {
              id: 0,
              account: '',
              transactionName: '',
              transactionType: 'expense',
              //currencyConversion: {},
              budget: 'None',
              comment: '',
              category: '',
              currency: '',
              transactionBalances: [],

              transferFrom: '',
              transferTo: '',

              isRecurring: false,

              //Recurring Transactions
              repeat: 1,
              cycle: '',
              period: 'Forever',
              numberOfCycles: 1,
              goesOffFirst: '',
              isActive: true,
              refillAmount: 0,

              //State of Current Object
              objectState: 'New',
              show: false
          },
          transactionBalances: {
              id: 0,
              transactionDate: '',
              transactionTime: null,
              transactionDiscount: 0,
              transactionAmount: 0,
              paymentType: '',
              foreignCurrencyAmount: 0,
              transactionItems: [],
          },
        transactionType: 1,
        conversions: [],
        currencyConversions: [],
        showDataOnSide: false,
        convert: false,
        transactions: []
      }
    },
    computed: {
        asMoney: function () {
            return (amount) => {
                return formatMoney(amount, 2, ".", ",")
            }
        },
      transactionNameErrors() {
        const errors = []
        if (!this.$v.editTransaction.transactionName.$dirty) return errors
        !this.$v.editTransaction.transactionName.required && errors.push('Transaction Name is required')
        return errors
      },
      accountErrors() {
        const errors = []
        if (!this.$v.editTransaction.account.$dirty) return errors
        !this.$v.editTransaction.account.required && errors.push('Account is required')
        return errors
      },
      transferFromErrors() {
        const errors = []
        if (!this.$v.editTransaction.transferFrom.$dirty) return errors
        !this.$v.editTransaction.transferFrom.required && errors.push('Tranfer from is required')
        return errors
      },
      transferToErrors() {
        const errors = []
        if (!this.$v.editTransaction.transferTo.$dirty) return errors
        !this.$v.editTransaction.transferTo.required && errors.push('Tranfer to is required')
        if (this.editTransaction.transferTo === this.editTransaction.transferFrom) errors.push('Cannot tranfer to the same Account')
        return errors
      },
        paymentTypeErrors() {
            const errors = []
            if (!this.$v.transactionBalances.paymentType.$dirty) return errors
            !this.$v.transactionBalances.paymentType.required && errors.push('Payment Type is required')
            return errors
        },
      //categoryErrors() {
      //  const errors = []
      //  if (!this.$v.editTransaction.category.$dirty) return errors
      //  !this.$v.editTransaction.category.required && errors.push('Category is required')
      //  return errors
      //},
        transactionAmountErrors() {
            const errors = []
            if (!this.$v.transactionBalances.transactionAmount.$dirty) return errors
            !this.$v.transactionBalances.transactionAmount.minValue && errors.push('Transaction Amount must be at least 1')
            return errors
        },
      currencyErrors() {
        const errors = []
        if (!this.$v.editTransaction.currency.$dirty) return errors
        !this.$v.editTransaction.currency.required && errors.push('Currency is required')
        return errors
        },
        //placeSoldErrors() {
        //    const errors = []
        //    if (!this.$v.editTransaction.placeSold.$dirty) return errors
        //    !this.$v.editTransaction.placeSold.required && errors.push('Place Sold is required')
        //    return errors
        //},
        //placeSoldLocationErrors() {
        //    const errors = []
        //    if (!this.$v.editTransaction.placeSoldLocation.$dirty) return errors
        //    !this.$v.editTransaction.placeSoldLocation.required && errors.push('Place Sold Location is required')
        //    return errors
        //},
      selectAndHideAccount() {
        //Find Correct Budget Object
        let bud = this.$store.state.Budgets
        for (let i = 0; i < bud.length; i++) {
          if (bud[i].budgetName === this.editTransaction.budget) {
            this.editTransaction.account = bud[i].account
          }
        }
      },
      adjustAccountToMatchBudget() {

        ////////////////////////////////////////////////////////
        //TODO: Check if Budget is not "None".
        //TODO: Show error and choice to user if not none
        //and account changed
        ////////////////////////////////////////////////////////

      }
    },
    methods: {
        adjustShow: function (data) {
            this.showDataOnSide = data
        },
        updateItemNumber: function () {
            if (this.editTransaction.transactionBalances[0].transactionItems.length < 100) {
                this.itemsBadge = this.editTransaction.transactionBalances[0].transactionItems.length
            }
            else {
                this.itemsBadge = "99+"
            }
        },
        returnPlaceSold: function (place) {
            this.editTransaction.placeSold = place
        },
      buildAccountSelect: function () {
        let tempAccount = this.$store.state.Accounts

        this.account.push({ header: 'Accounts' })

        for (let i = 0; i < tempAccount.length; i++) {
          this.account.push(tempAccount[i])
        }

        this.transferAccount = this.account

        //let group = this.$store.state.Groups

        //if (group.length > 0) {
        //  this.account.push({ header: 'Groups' })

        //  for (let i = 0; i < group.length; i++) {
        //    this.account.push(group[i])
        //  }
        //}

      },
      TransactionCheck: function ($v, $t) {
          $v.$touch(); //Triggers Error Messages
          $t.$touch(); //Triggers Error Messages

          this.editTransaction.transactionBalances[0] = this.transactionBalances

          if (this.editTransaction.transactionBalances[0].transactionAmount < 0
              || !globalFunctions.isIntOrFloat(this.editTransaction.transactionBalances[0].transactionAmount)
              || this.editTransaction.transactionBalances[0].transactionAmount === "")
          {
              this.editTransaction.transactionBalances[0].transactionAmount = 0
          }

        if (this.editTransaction.transactionType !== 'transfer') {
          if ($v.account.$invalid || $t.transactionAmount.$invalid || $v.currency.$invalid || $t.paymentType.$invalid) {
            return false
          }
        }
        else if (this.editTransaction.transactionType === 'transfer') {
          if ($v.transferFrom.$invalid || $v.transferTo.$invalid || $t.transactionAmount.$invalid || $v.currency.$invalid || $t.paymentType.$invalid) {
            return false
          }
        }
        return true
      },
      editTransactionCheck: function ($v, $t) {
        if (this.TransactionCheck($v, $t)) {

            if (this.editTransaction.transactionType === 'transfer') {
                this.editTransaction.account = this.editTransaction.transferFrom
            }

          transactionManager.updateEditedTransaction(this.editTransaction)
          

          //////////////////////////////////////
          //Transaction Successful
          //////////////////////////////////////

          //this.snackbarText = "Transaction Successful"
          //this.snackbarColor = "success"
          //this.showSnackbar = true

          this.$router.push({ path: '/dashboard/transaction' })
        }
      },
      editTransactionSetter: function (item) {
        for (let i = 0; i < this.transactions.length; i++) {
          if (this.transactions[i].id === parseInt(item)) {
              this.editTransaction = globalFunctions.stringify(this.transactions[i])
              this.editTransaction.transactionBalances[0].transactionDate = dateManager.reformatDateFromApi(this.editTransaction.transactionBalances[0].transactionDate)
              this.transactionBalances = this.editTransaction.transactionBalances[0]
            break
          }
        }
      },
      addItemToTransaction: function (item) {
          if (this.editTransaction.transactionBalances[0].transactionItems.length === 0) {
              this.editTransaction.transactionBalances[0].transactionItems.push(item)

          this.addItemPriceToTransactionAmount()
        }
        else {
            for (let i = 0; i < this.editTransaction.transactionBalances[0].transactionItems.length; i++) {
            if (this.transactionBalances.transactionItems[i].id === item.id) {

              this.updateTransactionAmount(this.transactionBalances.transactionItems[i], item)
                this.transactionBalances.transactionItems[i] = globalFunctions.stringify(item)
              break
            }

            if (i === this.transactionBalances.transactionItems.length - 1) {
              this.transactionBalances.transactionItems.push(item)
              this.addItemPriceToTransactionAmount()
              break
            }
          }
        }
      },
      updateTransactionAmount: function (olditem, newitem) {
        this.editTransaction.transactionBalances[0].transactionAmount = parseFloat(this.editTransaction.transactionBalances[0].transactionAmount) - parseFloat(this.currencyConverter(olditem))

        this.editTransaction.transactionBalances[0].transactionAmount = parseFloat(this.editTransaction.transactionBalances[0].transactionAmount) + parseFloat(this.currencyConverter(newitem))
      },
      addItemPriceToTransactionAmount: function () {

        let total = 0;
        for (let i = 0; i < this.transactionBalances.transactionItems.length; i++) {
          total = parseFloat(total) + parseFloat(this.currencyConverter(this.transactionBalances.transactionItems[i]))
        }

          this.editTransaction.transactionBalances[0].transactionAmount = total

          this.updateItemNumber()

      },
      //selectedCurrencyChanged: function () {
      //  ///////////////////////////////////////////////////////
      //  //Go Through Each Transaction Item 
      //  ///////////////////////////////////////////////////////

      //  for (let i = 0; i < this.transactionItems.length; i++) {
      //    ////////////////////////////////////////////////////////////////
      //    //Loop conversions and test if currency already found
      //    ///////////////////////////////////////////////////////////////

      //    for (let x = 0; x < this.conversions.length; x++) {
      //      if (this.transactionItems[i].itemCurrency === this.conversions[x]) {
      //        break
      //      } else if (x === this.conversions.length - 1) {
      //        this.getConversion(this.transactionItems[i].itemCurrency)
      //      }
      //    }
      //  }
      //},
      getConversion: function (from) {
        for (let i = 0; i < this.currencyConversions.length; i++) {
          if (this.currencyConversions[i].convertFrom === from && this.currencyConversions[i].convertTo === this.editTransaction.currency) {
            this.conversions.push(this.currencyConversions[i])
          }
        }

        this.convert = true
      },
      currencyConverter: function (item) {
        ////////////////////////////////////////////////////////////////////
        //Returns Price If Currencies Are The Same,
        //Returns Adjusted Price If Currency Is Different
        ////////////////////////////////////////////////////////////////////

        if (item.itemCurrency === this.editTransaction.currency || this.editTransaction.currency === "") {
          return item.itemPrice
        } else {
          for (let i = 0; i < this.conversions.length; i++) {
            if (this.conversions.convertFrom === item.itemCurrency) {
              return parseFloat(this.conversions.conversionRate) * item.itemPrice
            }
          }
        }

      },
      getAvailableCurriencies: function () {
        let cur = this.$store.state.Currencies

        for (let i = 0; i < cur.length; i++) {
          if (cur[i].ObjectState !== "Delete") {
            this.currency.push(cur[i])
          }
        }
        },
        //addPlaceSold: function (place) {
        //    this.placeSold = globalFunctions.returnAvailablePlaceSold()

        //    for (let i = 0; i < this.placeSold.length; i++) {
        //        if (this.placeSold[i].retailerName === place) {
        //            this.editTransaction.placeSold = this.placeSold[i].retailerName

        //            break
        //        }
        //    }
        //},
        },
        watch: {
            "editTransaction.placeSold": function () {
                if (!globalFunctions.isNullOrEmpty(this.editTransaction.placeSold)) {
                    for (let i = 0; i < this.placeSold.length; i++) {
                        if (this.placeSold[i].retailerName === this.editTransaction.placeSold) {
                            this.placeSoldLocations = this.placeSold[i].locations
                            break
                        }
                    }
                }
            }
        },
    beforeMount() {
      this.transactions = this.$store.state.Transactions

      let cat = this.$store.state.Categories
      for (let i = 0; i < cat.length; i++) {
        this.category.push(cat[i].categoryName)
      }

      let curCon = this.$store.state.CurrencyConversions
      for (let i = 0; i < curCon.length; i++) {
        this.currencyConversions.push(curCon[i])
      }

      let bud = this.$store.state.Budgets
      for (let i = 0; i < bud.length; i++) {
        this.budget.push(bud[i].budgetName)
      }

      this.buildAccountSelect()
        this.getAvailableCurriencies()
        this.placeSold = globalFunctions.returnAvailablePlaceSold()

      this.editTransactionSetter(this.$route.params.itemId)
      this.updateItemNumber()
    },
    validations: {
      editTransaction: {
            transactionName: {
                required
            },
            account: {
                required
            },
            //category: {
            //    required
            //},
            currency: {
                required
            },
            transferFrom: {
                required
            },
            transferTo: {
                required
            },
            //placeSold: {
            //    required
            //},
            //placeSoldLocation: {
            //    required
            //}

        },
        transactionBalances: {
            transactionDate: {
                required
            },
            transactionTime: {
                required
            },
            paymentType: {
                required
            },
            transactionAmount: {
                required,
                minValue: minValue(1)
            },
        }
      
    }


  }
</script>
